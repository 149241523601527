<template>
  <v-container id="voting-detail" fluid tag="section">
    <v-row justify="center">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
      <v-col cols="12" md="11">
        <base-material-card>
          <template v-slot:heading>
            <div class="text-center text-h3 font-weight-medium">{{ meeting.urban_name }}</div>
            <div class="text-center text-h3 font-weight-medium">{{ meeting.title }}</div>
          </template>
          <v-col cols="12" class="text-center text-h3">{{ $t('vote.issueTitle') }}{{ issue.title }}</v-col>
          <v-col
            cols="12"
            class="text-center text-h3"
          >{{ TransDate(meeting.meeting_time) }}</v-col>
          <v-divider class="mx-4"></v-divider>
          <v-dialog v-model="dialog" max-width="90vw">
            <v-card>
              <v-card>
                <v-col class="text-left">
                  <v-chip
                    class="ma-2"
                    color="indigo"
                    text-color="white"
                  >{{ $t('vote.voter') }}{{ selected_owner.owner_name }}：</v-chip>
                  <template v-for="item in selected_options">
                    <v-chip
                      class="ma-2"
                      color="green"
                      text-color="white"
                      close
                      @click:close="selectOption(item)"
                    >{{ item.option_name }}</v-chip>
                  </template>
                </v-col>
              </v-card>
              <v-card max-height="70vh" class="d-flex justify-space-around flex-wrap px-2 scroll">
                <template v-for="item in options">
                  <v-card
                    v-if="!item.is_removed"
                    class="pa-2 my-2"
                    :style="{border: ifSelected(item)}"
                    :width="countOptionsWidth()"
                    @click="selectOption(item)"
                  >
                    <v-card-title class="text-center pt-2 px-0 font-weight-medium">{{ item.option_name.split("_")[0] }}</v-card-title>
                    <v-card-title class="text-center pt-0 px-0 font-weight-medium">{{ item.option_name.split("_")[1] }}</v-card-title>
                  </v-card>
                </template>
                <template v-for="empty_item in empty_options_count">
                  <div class="empty_item"></div>
                </template>
              </v-card>
              <v-card>
                <v-col class="text-right">
                  <div class="d-flex">
                    <v-btn color="red" class="mx-2" :disabled="loading" @click="cancelVote()">{{ $t('vote.cancel') }}</v-btn>
                    <v-btn color="aqua" class="mx-2 ml-auto" @click="closeDialog()">{{ $t('common.cancel') }}</v-btn>
                    <v-btn color="primary" dark class="mx-2" :disabled="loading" @click="sendVote()">{{ $t('common.confirm') }}</v-btn>
                  </div>
                </v-col>
              </v-card>
            </v-card>
          </v-dialog>
          <v-card class="d-flex justify-space-around flex-wrap px-2">
            <template v-for="(item, index) in owners">
              <v-card class="pa-0" width="9.1%" @click="pickOwner(item)" :color="ifVotedCard(item)">
                <v-card-text class="text-center pa-2 py-4" style="color:black;background-color:#d7f0c2">{{ index<10 ? ('0' + (index+1)).slice(-2): index+1 }}</v-card-text>
                <v-card-text class="text-center font-weight-medium pa-2 py-4" :style="{color: ifVotedText(item)}">{{ Number(item.regno_no)<10 ? ('0' + item.regno_no).slice(-2): item.regno_no }} {{ issue.hide_name ? item.owner_name[0]+"OO" : item.owner_name }}</v-card-text>
              </v-card>
            </template>
            <template v-for="empty_item in empty_owners_count">
              <div class="empty_item"></div>
            </template>
          </v-card>
          <v-col cols="12" class="text-right">
            <v-btn color="aqua" class="mx-2" @click="back()">{{
              $t("common.back")
            }}</v-btn>
          </v-col>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: vm => ({
    loading: false,
    dialog_message: false,
    issue: { title: null },
    meeting: { meeting_time: null, urban_renewal: { name: null } },
    options: [],
    owners: [],
    votes: [],
    signIn_data: [],
    sign_ins: [],
    empty_owners_count: 0,
    empty_options_count: 0,
    selected_owner: { name: null },
    selected_options: [],
    selected_vote: null,
    updateVote: false,
    dialog: false,
    message: {
      title: "",
      text: ""
    },
    voteStatus: {
      "invalid": 1,
      "valid": 2,
      "cancel": 3,
    }
  }),
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/issue/" +
        this.issue_id +
        "/voting/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
          //   page: page,
          //   page_size: itemsPerPage,
          //   task: this.id
        }
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.meeting = info.meeting;
      this.issue = info.issue;
      //   this.totalItems = info.options.length;
      this.owners = info.owners;
      this.votes = info.votes;
      this.sign_ins = [];
      info.sign_ins.forEach(element=>{
        this.sign_ins.push(element.owner)
      })
      this.signIn_data = info.sign_ins;
      this.options = [];
      info.options.forEach(element => {
        if (!element.is_removed) {
          this.options.push(element);
        }
      });
      //   this.options = info.options;
      this.empty_owners_count = 10 - (this.owners.length % 10);
      if(this.options.length>5){
        this.empty_options_count = 10 - (this.options.length % 10);
      }
    },
    pickOwner(item) {
      let sign_in = this.signIn_data.find(element=>{
        return element.owner == item.id
      })
      if(sign_in == null || sign_in.status ==3){ // status ==3 取消出席
        return;
      }
      this.dialog = true;
      this.selected_owner = item;
      let selectedOptions = [];
      this.votes.forEach(element => {
        if (element.voter == item.id) {
          this.updateVote = true;
          selectedOptions = element.options_no_list.split(",");
          this.selected_vote = element;
        }
      });
      selectedOptions.forEach(option_id => {
        this.options.forEach(option => {
          if (option.id == option_id) {
            this.selected_options.push(option);
          }
        });
      });
    },
    selectOption(item) {
      let exist = false;
      let i = 0;
      for (i; i < this.selected_options.length; i++) {
        if (this.selected_options[i] === item) {
          exist = true;
          break;
        }
      }
      if (exist) {
        this.selected_options.splice(i, 1);
      } else {
        this.selected_options.push(item);
      }
    },
    sendVote() {
      // if (this.selected_options.length == 0) {
      //   return;
      // }
      let vote_status = this.voteStatus.valid;
      if (this.selected_options.length > this.issue.max_elect_count) {
        if (
          !confirm(
            this.$i18n.t('vote.notValid')
          )
        ) {
          return;
        } else {
          vote_status = this.voteStatus.invalid;
        }
      }
      let options_no_list = [];
      this.selected_options.forEach(element => {
        options_no_list.push(element.id);
      });
      if(options_no_list.length == 0){
        if(
          !confirm(this.$i18n.t('vote.voteNone'))
          ){
            return;
          }
      }
      if(this.selected_vote){
          this.patchVote(options_no_list, vote_status)
      } else {
          this.saveVote(options_no_list, vote_status)
      }
    },
    cancelVote(){
      let vote_status = this.voteStatus.cancel;
      if (
        !confirm(
          this.$i18n.t('vote.cancelVote')
        )
      ) {
        return;
      }
      let options_no_list = [];
      if(this.selected_vote){
          this.patchVote(options_no_list, vote_status)
      } else {
          this.saveVote(options_no_list, vote_status)
      }
    },
    saveVote(options_no_list, vote_status){
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/voteRecord/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data = {
        voter: this.selected_owner.id,
        issue: this.issue.id,
        options_no_list: options_no_list.toString(),
        vote_status: vote_status
      };
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.getApi();
          currentObj.closeDialog();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    patchVote(options_no_list, vote_status) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/voteRecord/" + this.selected_vote.id + "/";

      const jsonData = {
        voter: this.selected_owner.id,
        issue: this.issue.id,
        options_no_list: options_no_list.toString(),
        vote_status: vote_status
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function(response) {
          currentObj.getApi();
          currentObj.closeDialog();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
      this.dialog_form = false;
    },
    closeDialog(){
      this.dialog = false;
    },
    countOptionsWidth(){
      let option_width = ''
      if(this.options.length>5){
        option_width = '9.1%'
      } else {
        let item_width = 91 / this.options.length
        option_width = item_width.toString()+'%'
      }
      return option_width
    },
    ifSelected(item){
      let color = "#ffffff"
      let i = 0;
      for (i; i < this.selected_options.length; i++) {
        if (this.selected_options[i] === item) {
          color = "#2699FB"
          break;
        }
      }
      return "8px solid "+color
    },
    ifVotedCard(item){
      let color = "#ffffff"
      this.votes.forEach(element => {
        if (element.voter == item.id && element.vote_status != this.voteStatus.cancel) {
          color = "#2699FB"
        }
      });
      let sign_in = this.signIn_data.find(element=>{
        return element.owner == item.id
      })
      if(sign_in == null){
        color = "#C9C9C9"
      } else if(sign_in.status == 3){ // 取消出席
        color = "#C9C9C9"
      }
      return color
    },
    ifVotedText(item){
      let color = "#333333"
      this.votes.forEach(element => {
        if (element.voter == item.id && element.vote_status != this.voteStatus.cancel) {
          color = "#ffffff"
        }
      });
      return color
    },
    back(){
      this.$router.push({
        name: "IssueList",
        query: {
          item: this.meeting_id,
        },
      });
    },
    receiveSocketData(message) {
      let info = JSON.parse(message.data);
      let new_sign_in = info.data;
      let sign_in = this.signIn_data.find(element=>{
        return element.owner == new_sign_in.owner
      })
      if (info.action == 2) {
        if(sign_in == null){
          this.signIn_data.push(new_sign_in);
        } else {
          sign_in.status = new_sign_in.status;
        }
        if(new_sign_in.status != 3){
          if(this.sign_ins.indexOf(new_sign_in.owner) == -1){
            this.sign_ins.push(new_sign_in.owner)
          }
        } else if(this.sign_ins.indexOf(new_sign_in.owner) != -1){
          this.sign_ins.splice(this.sign_ins.indexOf(new_sign_in.owner), 1)
        }
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.selected_options = [];
        this.updateVote = false;
        this.selected_vote = null;
      }
    }
  },
  mounted() {
    this.$options.sockets.onmessage = this.receiveSocketData;
    this.setTitle(this.$i18n.t("vote.startVoting"));
    this.issue_id = this.$route.query.item;
    this.meeting_id = this.$route.query.meeting;
    this.getApi();
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  }
};
</script>

<style lang="scss" scoped>
.empty_item {
  width: 9.1%;
}
.scroll {
  overflow-y: scroll;
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

.v-dialog
  .v-chip
      .v-chip__content
          font-size: 16px
</style>